<template>
  <div>
    <div class="pos-header">
      <v-btn
        v-for="(row, index) in categories"
        :key="index"
        depressed
        v-on:click="getTables(row)"
        :color="getTableButtonColor(row)"
        tile
        class="mr-3"
        >{{ row }}</v-btn
      >
      <v-btn
        v-if="!show_table"
        depressed
        tile
        color="cyan white--text"
        class="ml-4"
        v-on:click="show_table = true"
        >Tables</v-btn
      >
    </div>
    <div class="mt-4 w-100">
      <v-row>
        <v-col md="4" class="pos-table-orders">
          <v-tabs>
            <v-tab>All</v-tab>
            <v-tab>Unpaid</v-tab>
            <v-tab>Paid</v-tab>
            <v-tab>Cancelled</v-tab>
          </v-tabs>
          <v-list class="pos-table-order-list" three-line>
            <template v-for="o in 10">
              <v-list-item v-on:click="show_table = false" class="pos-table-order" :key="o">
                <v-list-item-avatar class="m-0 my-auto">
                  <v-icon class="blue" dark> mdi-clipboard-text </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="mx-4">
                  <v-list-item-title>#TTR1234</v-list-item-title>
                  <v-list-item-subtitle>order</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>RM 15.90</v-list-item-action-text>
                  <v-list-item-action-text>20:15</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
        <v-col md="8" class="row pos-tables">
          <template v-if="show_table">
            <div
              v-for="(row, index) in tables"
              :key="index"
              class="cursor-pointer pos-table text-center"
              v-on:click="routeToOrder(row.id)"
              :class="getTableClasses(row.status)"
            >
              <div class="text-center">
                <p class="m-0">{{ row.name }}</p>
                <v-chip
                  label
                  x-small
                  :color="getTableColor(row.category)"
                  text-color="white"
                  class="justify-center h-20px"
                  >{{ row.category_name }}</v-chip
                >
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pos-order-detail w-100">
              <h4>Order No. ABCD123456789</h4>
              <div>
                <v-tabs v-model="order_detail_tab">
                  <v-tab href="#payment">Payment</v-tab>
                  <v-tab href="#items">Items</v-tab>
                </v-tabs>
                <v-tabs-items v-model="order_detail_tab">
                  <v-tab-item value="payment">
                    <table class="w-100 mt-4 payment-detail">
                      <tbody>
                        <tr>
                          <td colspan="2">Item's Total Price: RM 35.50</td>
                        </tr>
                        <tr>
                          <td colspan="2">Receivable: RM 35.50</td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            Cash: RM 35.50
                            <v-chip small color="ml-4 green white--text"> Paid </v-chip>
                            <v-chip small color="ml-4 red lighten-1 white--text"> Unpaid </v-chip>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">Changes: RM 35.50</td>
                        </tr>
                        <tr>
                          <td>Payee: Lilian</td>
                          <td>Actual Collection: RM 35.50</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-tab-item>
                  <v-tab-item value="items">
                    <table class="w-100 mt-4 item-detail">
                      <thead>
                        <tr>
                          <th width="60%">Item</th>
                          <th width="10%" class="text-center">QTY</th>
                          <th width="15%" class="text-center">Unit Price</th>
                          <th width="15%" class="text-center">Sub Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width="60%">024 Meechoon Soup</td>
                          <td width="10%" class="text-center">1</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                        </tr>
                        <tr>
                          <td width="60%">024 Meechoon Soup</td>
                          <td width="10%" class="text-center">1</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                        </tr>
                        <tr>
                          <td width="60%">024 Meechoon Soup</td>
                          <td width="10%" class="text-center">1</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                        </tr>
                        <tr>
                          <td width="60%">024 Meechoon Soup</td>
                          <td width="10%" class="text-center">1</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                        </tr>
                        <tr>
                          <td width="60%">024 Meechoon Soup</td>
                          <td width="10%" class="text-center">1</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                        </tr>
                        <tr>
                          <td width="60%">024 Meechoon Soup</td>
                          <td width="10%" class="text-center">1</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                          <td width="15%" class="text-center">RM 10.90</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { GET_TABLES } from "@/core/lib/pos.lib";
import POSMixin from "@/core/mixins/pos.mixin";
import { toLower, isArray } from "lodash";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
  mixins: [POSMixin],
  data() {
    return {
      show_table: true,
      tables: [],
      categories: [],
      table_loading: false,
      order_detail_tab: null,
    };
  },
  methods: {
    getTableButtonColor(category) {
      const cat = toLower(category);
      if (cat == "dine-in") {
        return "light-blue darken-4 white--text";
      } else if (cat == "takeaway") {
        return "cyan darken-4 white--text";
      } else if (cat == "delivery") {
        return "teal darken-4 white--text";
      } else {
        return "indigo darken-1 white--text";
      }
    },
    getTableColor(category) {
      if (category == 1) {
        return "light-blue darken-4 white--text";
      } else if (category == 2) {
        return "cyan darken-4 white--text";
      } else if (category == 3) {
        return "teal darken-4 white--text";
      } else {
        return "indigo darken-1 white--text";
      }
    },
    routeToOrder(table) {
      this.$router.push({
        name: "pos-order",
        query: { table, t: new Date().getTime() },
      });
    },
    getTableClasses(status) {
      let result = null;
      switch (status) {
        case "printed":
          result = "light-green accent-1";
          break;
        case "running_kot":
          result = "amber darken-1";
          break;
        case "empty_table":
          result = "blue-grey lighten-3";
          break;
      }
      return result;
    },
    getTables(category) {
      GET_TABLES({ category })
        .then((data) => {
          this.tables = data;
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        })
        .finally(() => {
          this.table_loading = false;
        });
    },
  },
  mounted() {
    this.getTables();
    this.categories = this.getConfig("category");
    if (this.categories && isArray(this.categories)) {
      this.categories.unshift("All");
    }
  },
};
</script>
